@keyframes loading-text {
  from { opacity: 1; }
  to { opacity: 0; }
  // 100% { opacity: 1 }
}

.bg-progress {
  background-color: #ffb6b6 !important;
}

body, .dropdown-menu {
  font-size: 14px;
  // padding-top: 70px;
//  background-color: rgb(241, 242, 246);
}

.debts-matrix .dropdown-menu {
  width: 15rem;
  transform: translate3d(-105px, 38px, 0px) !important; 
}

// .select2-container{
//   z-index: 10050 !important;      
// }

.m-bt-1r {
  margin-bottom: 1rem;
}

.loading-title {
  animation-name: loading-text;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-direction: alternate;
}

.font-21 {
  font-size: 21px;
}

.alert-alert {
  background-color: #ffdd87;
  border: #ffce54;
}

.alert-notice {
  background-color: #7cd1ef;
  border: #4fc1e9;
}

.navbar .divider-vertical {
  height: 50px;
  margin: 0 9px;
  border-right: 1px solid #ccd1d9;
}


.middle, tr.middle td {
  vertical-align: middle !important;
}


div.header {
  margin-bottom: 10px;
  height: auto;

  .actions :not(:last-child) {
    margin-bottom: 5px;
  }
}

div.search {
  margin-bottom: 15px;
}

.ac {
  text-align: center;
}

.ar {
  text-align: right;
}

th.controls {
  width: 160px;
}

th.panel-table {
  width: 30%;
}

.bigdrop.select2-container .select2-results { max-height: 350px; }
.bigdrop .select2-results { max-height: 350px; }

p.error {
  color: #a94442;
}

.colorgraph {
  height: 5px;
  border-top:0;
  background: #c4e17f;
  border-radius: 5px;
  background-image: -webkit-linear-gradient(left, #c4e17f, #c4e17f 12.5%, #f7fdca 12.5%, #f7fdca 25%, #fecf71 25%, #fecf71 37.5%, #f0776c 37.5%, #f0776c 50%, #db9dbe 50%, #db9dbe 62.5%, #c49cde 62.5%, #c49cde 75%, #669ae1 75%, #669ae1 87.5%, #62c2e4 87.5%, #62c2e4);
  background-image: -moz-linear-gradient(left, #c4e17f, #c4e17f 12.5%, #f7fdca 12.5%, #f7fdca 25%, #fecf71 25%, #fecf71 37.5%, #f0776c 37.5%, #f0776c 50%, #db9dbe 50%, #db9dbe 62.5%, #c49cde 62.5%, #c49cde 75%, #669ae1 75%, #669ae1 87.5%, #62c2e4 87.5%, #62c2e4);
  background-image: -o-linear-gradient(left, #c4e17f, #c4e17f 12.5%, #f7fdca 12.5%, #f7fdca 25%, #fecf71 25%, #fecf71 37.5%, #f0776c 37.5%, #f0776c 50%, #db9dbe 50%, #db9dbe 62.5%, #c49cde 62.5%, #c49cde 75%, #669ae1 75%, #669ae1 87.5%, #62c2e4 87.5%, #62c2e4);
  background-image: linear-gradient(to right, #c4e17f, #c4e17f 12.5%, #f7fdca 12.5%, #f7fdca 25%, #fecf71 25%, #fecf71 37.5%, #f0776c 37.5%, #f0776c 50%, #db9dbe 50%, #db9dbe 62.5%, #c49cde 62.5%, #c49cde 75%, #669ae1 75%, #669ae1 87.5%, #62c2e4 87.5%, #62c2e4);
}

table {
  &.layout-fixed {
    table-layout: fixed;
  }

  &.table-small {
    font-size: 80%;
  }

  td.break-word {
    word-wrap: break-word;
  }
}

.datepicker, .no-padding {
  padding: 0 !important;
}

.datetimepicker {
  width: 200px;
}

.select2-container-multi .select2-choices {
  .select2-search-choice {
    margin-top: 5px;
  }
  .select2-search-choice-close {
    top: 3px;
  }
}

// html.turbolinks-progress-bar::before {
//   height: 3px !important;
// }

.border-block {
  border-radius: 0;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  font-size: 100px;
  min-height: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.form-horizontal {
  .progress {
    margin-top: 9px;
    margin-bottom: 0px;
    background-color: #eaeaea;
  }
}
// .group-aligned .checkbox {
//   @extend .col-md-9;
//   @extend .col-md-offset-3;
// }

// .spin-icon:hover i {
//   @extend .fa-spin;
// }

span.label.wrapped {
  display: inline-block;
  white-space: initial;
}

.form-group .aligned-labels label {
  vertical-align: top;
}

.row {
  &.marginless {
    margin-left: 0;
    margin-right: 0;
  }
  .separated-column {
    border-left: 1px solid #ccc;
  }
}

input {
  &.number-input {
    min-width: 82px;
    max-width: 15%;
  }

  &.compact-file-input {
    border: none;
    box-shadow: none;
    padding-left: 0;
    padding-bottom: 0;
    // height: 27px;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

.separated {
  margin-bottom: 5px;
}

.btn {
  &.btn-pill.bordered {
    border-color: #ccc;
  }

  &.btn-times {
    padding: 6px 6px;
  }
}

.modal .modal-content .panel {
  margin-bottom: 0;
}

.text-left {
  text-align: left !important;
}

input[type="radio"], input[type="checkbox"] {
  vertical-align: top;
}

.television-programs-container a.select2-choice {
  height: 34px;
  line-height: 34px;

  .select2-arrow b {
    // background: asset-url("select2.png") no-repeat 0 4px;
  }
}

pre.logs {
  margin-bottom: 0px;
  border-radius: 0;
}

.panel-group {
  .panel-title {
     margin-top: 2px;
  }

  .list-group {
    margin-bottom: 0;

    .row.marginless {
      display: table;
      width: 100%;
    }

    .list-group-item.borderless {
      float: none;
      display: table-cell;
      border-radius: 0;
      vertical-align: middle;
    }
  }
}

.foundation-table {
  td, th {
    border: 1px solid #ddd;
    input {
      border-radius: 0;
    }
  }
}

.reports-container {
  display: flex;
  flex-direction: column;
  margin: 4rem 0;
  padding: 16px;
  border: 1px solid #80808070;
  border-radius: 8px;
}

.reports-row {
  display: flex;
  flex-direction: row;
}

.reports-cell {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.reports-title {
  padding: 16px;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination li {
  display: inline;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination>li:first-child>a, .pagination>li:first-child>span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination>.active>a, .pagination>.active>a:hover, .pagination>.active>a:focus, .pagination>.active>span, .pagination>.active>span:hover, .pagination>.active>span:focus {
  z-index: 2;
  color: white;
  background-color: #428bca;
  border-color: #428bca;
  cursor: default;
}

.pagination>li>a, .pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.428571429;
  text-decoration: none;
  color: #428bca;
  background-color: white;
  border: 1px solid #dddddd;
  margin-left: -1px;
}

.pagination>li:last-child>a, .pagination>li:last-child>span {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination>li>a, .pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.428571429;
  text-decoration: none;
  color: #428bca;
  background-color: white;
  border: 1px solid #dddddd;
  margin-left: -1px;
}

select.time {
  width: 25%;
  display: inline;
}

#television_program_length_hms {
  height: calc(1.5em + 0.75rem + 2px);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.text-decoration-none {
  text-decoration: none;
}

a.close:hover {
  cursor: pointer;
}

.length-hms input.form-control:focus {
  box-shadow: none !important;
  border-width: 2px;
}

.length-hms input#television_program_hour {
  border-radius: 0.25rem 0 0 0.25rem;
}

.length-hms input#television_program_minute {
  border-radius: 0;
  border-width: 1px 0 1px 0;

  &:focus{
    border-width: 2px;
  }
}

.length-hms input#television_program_second {
  border-radius: 0 0.25rem 0.25rem 0;
}

[aria-invalid="true"], .has-error .select2 .select2-selection {
  border-color: red;
}

.help-block {
  color: red;
}

.hidden {
  display: none;
}