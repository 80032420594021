// @import "~bootstrap/scss/bootstrap";
@import "bootstrap/scss/bootstrap.scss";
// @import "bootstrap";
@import "~@fortawesome/fontawesome-free";
// @import 'bootstrap-datepicker';
@import "select2";
@import "select2-bootstrap-css";
// @import "font-awesome-sass";
// @import "font-awesome-sprockets";
// @import "select2-bootstrap-css";
// @import "font-awesome-sass";
// @import "datepicker3";
// @import "dropdown-enhancements.min";
// @import 'colorbox';

@import "./breadcrumb.scss";
@import "./custom.scss";
@import "./salary_structures.scss";
// @import "./selfservice.scss";
@import "./signin.scss";

// @import "selfservice.scss";

// .select2-selection--multiple {
//   overflow: hidden !important;
//   height: auto !important;
// }

.d-flex[style*="display:none"], .d-flex[style*="display: none"] {
  display:none !important;
}

.svg-inline--fa {
  height: 14px;
  width: 14px;
}

.datepicker {
  padding: 10px !important;
}

.select2-selection {
  min-height: 38px !important;
  &--multiple {
    overflow: hidden !important;
    height: auto !important;
  }
}

.select2-selection__rendered, .select2-selection--single .select2-selection__arrow, .select2-selection--single .select2-selection__clear {
  margin-top: 4px !important;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 8px !important;
  margin-left: 10px !important;
}

#cboxLoadedContent * { 
  overflow-x:hidden; 
}

.background-danger {
  background-color: #d9534f;
  color: white;
}

body.public {
  padding-top: 125px;
  background-color: #eee;
  // background-image: image-url('blur.jpg');
}

#logo {
  float: left;
  margin: 10px 10px 10px 20px;
}

abbr { 
  color: red;
}

.main {
  -webkit-filter: blur(5px) grayscale(50%);

  -webkit-transform: scale(0.9);
}

body.colorbox {
  background-color: #e6e6e6;
}

#colorbox {
  background-color: #fff;
}

.panel-heading {
  &.with-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.1;
  }
}